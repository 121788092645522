<template>
  <div class="layout">
    <Header :hasSearch="false" />
    <div class="layout-guide">
      <div class="layout-guide-space"></div>
      <router-link to="/">
        <img src="../../assets/images/guide/home.png" />
        首页</router-link
      >
      <div class="divider"></div>
      <a href="javascript:;" @click="publish">
        <img src="../../assets/images/icon/video_add.png" alt="" />
        发布视频
      </a>
      <router-link to="/ucenter/video">
        <img src="../../assets/images/icon/video.png" alt="" />作品管理
      </router-link>

      <router-link to="/ucenter/comment">
        <img src="../../assets/images/icon/comment.png" alt="" />评论管理
      </router-link>

      <router-link to="/ucenter/fav">
        <img src="../../assets/images/icon/fav.png" alt="" />我的收藏
      </router-link>
      <div class="divider"></div>
      <h3>机构</h3>

      <router-link to="/ucenter/org">
        <img src="../../assets/images/icon/org.png" alt="" />{{
          user.org ? "机构信息" : "机构认证"
        }}
      </router-link>
      <div class="divider"></div>

      <router-link to="/ucenter/follow">
        <img src="../../assets/images/icon/follow.png" alt="" />我的关注
      </router-link>
      <router-link :to="'/vlog/' + user.id" target="_blank">
        <img src="../../assets/images/icon/homepage.png" alt="" />个人主页
      </router-link>
      <router-link to="/ucenter/profile">
        <img src="../../assets/images/icon/profile.png" alt="" />个人资料
      </router-link>
      <router-link to="/ucenter/password">
        <img src="../../assets/images/icon/password.png" alt="" />修改密码
      </router-link>
      <a href="javascript:;" @click="$store.dispatch('userLogout')">
        <img src="../../assets/images/icon/logout.png" alt="" />退出登录</a
      >
    </div>
    <div class="layout-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
export default {
  name: "Layout",
  components: {
    Header,
  },
  watch: {
    $route: {
      handler: function () {
        this.checkAuth();
      },
      deep: true,
    },
  },
  metaInfo() {
    return {
      title: this.$route.meta.title + "-用户中心-" + this.Settings.base.name,
    };
  },
  mounted() {
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      this.$axios.get("/api/front/user/user/getUserInfo").then((res) => {});
    },
    publish() {
      location.replace("/ucenter/publish");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>